export default {
  enpl: "EN",
  enplink: "https://brownpeak.com",
  welcome: "Brown Peak wita",
  help: "Co proponujemy",
  re: "Agent nieruchomości",
  fin: "Doradca finansowy",
  interim: "interim manager",
  lin: "Linki",
  charity: "Pomagaj"
}
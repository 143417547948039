import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import enlang from '../locale/en'
import pllang from '../locale/pl'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#631905'
            }
        }
    },
    lang: {
        locales: {enlang, pllang},
        current: 'enlang'
    }
});

export default {
  enpl: "PL",
  enplink: "https://brownpeak.pl",
  welcome: "Welcome to Brown Peak",
  help: "How can we help",
  re: "Real estate agent",
  fin: "Financial Advisor",
  interim: "interim manager",
  lin: "Important Links",
  charity: "Charity"
}
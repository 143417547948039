<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Brown Peak Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo_2.png')"
          transition="scale-transition"
          width="100"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="0"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="0"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="changelang()"
        target="_blank"
        text
      >
        <span class="mr-2">{{$vuetify.lang.t('$vuetify.enpl')}}</span>
    </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: function(){
    if(window.location.host.endsWith(".pl")){
      this.$vuetify.lang.current = 'pllang'
    }
    else
      this.$vuetify.lang.current = 'enlang'
  },
  methods: {
    changelang: function(){
      if(this.$vuetify.lang.current == 'pllang')
        this.$vuetify.lang.current = 'enlang'
      else
        this.$vuetify.lang.current = 'pllang'
    }
  }
};
</script>

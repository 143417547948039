<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_3.png')"
          class="my-3"
          contain
          height="80"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          {{$vuetify.lang.t('$vuetify.welcome')}}
        </h1>

        <p class="subheading font-weight-regular">
          e-mail: <a href="mailto:marek.masny@brownpeak.com">marek.masny@brownpeak.com</a>
          <br>tel.: +48 669 930 257
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          {{$vuetify.lang.t('$vuetify.help')}}
        </h2>

        <v-row justify="center">
          <p
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ $vuetify.lang.t('$vuetify.'+next.text) }}
        </p>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          {{$vuetify.lang.t('$vuetify.lin')}}
        </h2>

        <v-row justify="center">
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          {{$vuetify.lang.t('$vuetify.charity')}}
        </h2>

        <v-row justify="center">
          <a
            v-for="(eco, i) in charity"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: function() {
      return {
      charity: [
        {
          text: 'siepomaga.pl',
          href: 'https://www.siepomaga.pl/',
        },
      ],
      importantLinks: [
        {
          text: 'LinkedIn',
          href: 'https://www.linkedin.com/in/mmasny/?originalSubdomain=pl',
        },
      ],
      whatsNext: [
        {
          text: 're',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'fin',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'interim',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
      }
    },
  }
</script>
